import React from 'react';
import url from '../../Assets/banner-8.png';

const PageHeader = () => {
    return (
        <section className="h-screen bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${url})` }}>
            <main className="flex h-full w-full items-center justify-end">
                <section className="w-full md:w-1/2 lg:w-1/3 p-8 text-right">
                    <div className="text-section">
                        <p className="text-7xl font-bold">Services</p>
                        <p className="mt-4 mb-64">
                            Discover Comprehensive Health Solutions at My Care Labs. From
                            infectious diseases to wellness and Toxicology, we're here for your
                            well-being.
                        </p>
                    </div>
                    <div className="flex items-center justify-end gap-5">
                        <button className="flex items-center rounded-full h-10 w-10 text-white justify-center hover:scale-95 transition-all duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-map-pin">
                                <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path>
                                <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                        </button>
                    </div>
                </section>
            </main>
        </section>
    );
};

export default PageHeader;
