import React from 'react'
import harry from '../../Assets/Harry.png'

const Team = () => {
  return (
    <section>
    {/* Container */}
    <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
      {/* Heading Div */}
      <div className="text-center">
        <h2 className="text-3xl font-semibold md:text-5xl">Our Team Members</h2>
        <p className="mx-auto mb-8 mt-4 max-w-[528px] text-[#636262] md:mb-12 lg:mb-16">Lorem ipsum dolor sit amet elit ut aliquam</p>
      </div>
      {/* Team Div */}
      <div className="mx-auto grid max-w-[1040px] grid-cols-1 justify-items-center gap-5 sm:grid-cols-2 sm:justify-items-stretch md:grid-cols-3 md:gap-4 lg:gap-6">
        {/* Item */}
        <div className="flex flex-col items-center justify-center gap-4 rounded-xl border border-solid border-[#636262] p-4 [box-shadow:rgb(0,_0,_0)_6px_6px]">
          <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980b423c019a5273ee5c6e_Rectangle%2040024.jpg" alt="" className="mb-4 inline-block h-56 w-full rounded-xl object-cover lg:h-56" />
          <p className="mb-2 font-bold">John Brooklyn</p>
          <p className="mb-2 text-sm text-[#636262]">Developer</p>
          <div className="mt-4 flex flex-wrap">
            <a href="#" className="mr-4 flex h-8 w-8 max-w-full flex-col items-center justify-center rounded-sm bg-[#ecf2ff] px-2 font-bold text-[#1353fe]">
              <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980be38447c887bfd955b8_InstagramLogo.svg" alt="" className="inline-block h-full w-full" />
            </a>
            <a href="#" className="mr-4 flex h-8 w-8 max-w-full flex-col items-center justify-center rounded-sm bg-[#ecf2ff] px-2 font-bold text-[#1353fe]">
              <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980be307547130d2e965fd_SlackLogo.svg" alt="" className="inline-block h-full w-full" />
            </a>
            <a href="#" className="flex h-8 w-8 max-w-full flex-col items-center justify-center rounded-sm bg-[#ecf2ff] px-2 font-bold text-[#1353fe]">
              <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980be16658297f6be979d2_TwitterLogo.svg" alt="" className="inline-block h-full w-full" />
            </a>
          </div>
        </div>
        {/* Item */}
        <div className="flex flex-col items-center justify-center gap-4 rounded-xl border border-solid border-[#636262] p-4 [box-shadow:rgb(0,_0,_0)_6px_6px]">
          <img src={harry} alt="" className="mb-4 inline-block h-56 w-full rounded-xl object-cover lg:h-56" />
          <p className="mb-2 font-bold">Harry Singh</p>
          <p className="mb-2 text-sm text-[#636262]">seller and Buyer</p>
          <div className="mt-4 flex flex-wrap">
            <a href="#" className="mr-4 flex h-8 w-8 max-w-full flex-col items-center justify-center rounded-sm bg-[#ecf2ff] px-2 font-bold text-[#1353fe]">
              <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980be38447c887bfd955b8_InstagramLogo.svg" alt="" className="inline-block h-full w-full" />
            </a>
            <a href="#" className="mr-4 flex h-8 w-8 max-w-full flex-col items-center justify-center rounded-sm bg-[#ecf2ff] px-2 font-bold text-[#1353fe]">
              <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980be307547130d2e965fd_SlackLogo.svg" alt="" className="inline-block h-full w-full" />
            </a>
            <a href="#" className="flex h-8 w-8 max-w-full flex-col items-center justify-center rounded-sm bg-[#ecf2ff] px-2 font-bold text-[#1353fe]">
              <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980be16658297f6be979d2_TwitterLogo.svg" alt="" className="inline-block h-full w-full" />
            </a>
          </div>
        </div>
        {/* Item */}
        <div className="flex flex-col items-center justify-center gap-4 rounded-xl border border-solid border-[#636262] p-4 [box-shadow:rgb(0,_0,_0)_6px_6px]">
          <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980b390754711c1de95455_Rectangle%2040024-2.jpg" alt="" className="mb-4 inline-block h-56 w-full rounded-xl object-cover lg:h-56" />
          <p className="mb-2 font-bold">John Brooklyn</p>
          <p className="mb-2 text-sm text-[#636262]">Developer</p>
          <div className="mt-4 flex flex-wrap">
            <a href="#" className="mr-4 flex h-8 w-8 max-w-full flex-col items-center justify-center rounded-sm bg-[#ecf2ff] px-2 font-bold text-[#1353fe]">
              <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980be38447c887bfd955b8_InstagramLogo.svg" alt="" className="inline-block h-full w-full" />
            </a>
            <a href="#" className="mr-4 flex h-8 w-8 max-w-full flex-col items-center justify-center rounded-sm bg-[#ecf2ff] px-2 font-bold text-[#1353fe]">
              <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980be307547130d2e965fd_SlackLogo.svg" alt="" className="inline-block h-full w-full" />
            </a>
            <a href="#" className="flex h-8 w-8 max-w-full flex-col items-center justify-center rounded-sm bg-[#ecf2ff] px-2 font-bold text-[#1353fe]">
              <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63980be16658297f6be979d2_TwitterLogo.svg" alt="" className="inline-block h-full w-full" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default Team
