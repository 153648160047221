import { MotionConfig, motion } from "framer-motion";

const Testimonial = ({ imgSrc, name, title, company, content }) => (
  <MotionConfig
    transition={{
      duration: 0.2,
      ease: "easeInOut",
    }}
  >
    <motion.div
      initial={{
        y: 0,
      }}
      animate={{
        y: -8,
      }}
      exit={{
        y: 0,
      }}
      className="w-full overflow-hidden rounded-lg border-2 bg-white p-8 md:p-12"
    >
      <div className="mb-6 flex items-center gap-6">
        <div className="rounded-lg">
          <motion.img
            initial={{
              rotate: "0deg",
              opacity: 0,
            }}
            animate={{
              rotate: "3deg",
              opacity: 1,
            }}
            exit={{
              rotate: "0deg",
              opacity: 0,
            }}
            src={imgSrc}
            alt="avatar"
            className="size-24 rounded-lg border-2"
          />
        </div>
        <motion.div
          initial={{
            y: 12,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: -12,
            opacity: 0,
          }}
        >
          <span className="mb-1.5 block text-3xl font-medium">{name}</span>
          <span className="text-zinc-600">
            {title} – <span className="text-indigo-600">{company}</span>
          </span>
        </motion.div>
      </div>
      <motion.p
        initial={{
          y: 12,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        exit={{
          y: -12,
          opacity: 0,
        }}
        className="text-xl leading-relaxed"
      >
        {content}
      </motion.p>
    </motion.div>
  </MotionConfig>
);

export const OPTIONS = [
  // const scrapBuyers = [
    {
      title: "Aluminium",
      Content: () => (
        <Testimonial
          imgSrc="https://www.globalirontraders.com/images/services/aluminium-scrap.webp"
          name="Aluminium Scrap Buyer"
          title="CEO"
          company="The Company"
          content="We specialize in buying aluminium scrap and provide convenient collection services right at your doorstep, whether it's your home, office, factory, or company. Trust us for reliable and efficient aluminium scrap buying solutions."
        />
      ),
    },
    {
      title: "Computer Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://www.globalirontraders.com/images/services/computer-scrap.webp"
          name="Computer Scrap Buyer"
          title="YouTuber"
          company="@TheChannel"
          content="We specialize in purchasing computer scrap and offer hassle-free collection services directly at your location, be it your home, office, factory, or company. Count on us for reliable and efficient computer scrap buying solutions."
        />
      ),
    },
    {
      title: "Iron",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/dirty-dumped-objects-arrangement_23-2148996942.jpg?t=st=1720108219~exp=1720111819~hmac=7f775584ac06bbdb947f3f47433386cedb7371e0a889b01b95247f8f86443e3c&w=360"
          name="Iron Scrap Buyer"
          title="Writer"
          company="theblog.com"
          content="We specialize in purchasing iron scrap and offer convenient collection services right at your doorstep, whether it's your home, office, factory, or company. Trust us for reliable and efficient iron scrap buying solutions."
        />
      ),
    },
    {
      title: "Copper",
      Content: () => (
        <Testimonial
          imgSrc="https://t3.ftcdn.net/jpg/03/76/01/90/240_F_376019027_YYGojOuZ4zWRWnVRRLJWYPLJtUJiqNZF.jpg"
          name="Copper Scrap Buyer"
          title="Author"
          company="Books About Things"
          content="We specialize in purchasing copper scrap and provide hassle-free collection services directly at your location, be it your home, office, factory, or company. Count on us for reliable and efficient copper scrap buying solutions."
        />
      ),
    },
    {
      title: "Brass",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/gold-textured-background_53876-15082.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Brass Scrap Buyer"
          title="UI/UX Designer"
          company="The Other Company"
          content="We are dedicated to buying brass scrap and offer seamless collection services at your convenience, whether at your home, office, factory, or company. Trust us for dependable and efficient brass scrap purchasing solutions."
        />
      ),
    },
    {
      title: "Electronic Scraps",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/assortment-dirty-dumped-objects_23-2148996979.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Electronic Scrap Buyer"
          title="Consultant"
          company="The Company Company"
          content="We specialize in purchasing electronic scrap and provide convenient collection services right at your doorstep, whether it's your home, office, factory, or company. Trust us for reliable and efficient electronic scrap buying solutions."
        />
      ),
    },
    {
      title: "Battery Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/high-view-battery-pollution-waste_23-2148764794.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Battery Scrap Buyer"
          title="Photographer"
          company="@ThePictureLady"
          content="We are experts in buying battery scrap and offer hassle-free collection services at your location, whether it’s your home, office, factory, or company. Rely on us for efficient and trustworthy battery scrap purchasing solutions."
        />
      ),
    },
    {
      title: "Electrical Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/beans-market-india_1353-68.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Electrical Scrap Buyer"
          title="Film Maker"
          company="@MovieDude"
          content="We specialize in buying electrical scrap and provide seamless collection services right at your doorstep, be it your home, office, factory, or company. Count on us for reliable and efficient electrical scrap purchasing solutions."
        />
      ),
    },
    {
      title: "Lead Scrap",
      Content: () => (
        <Testimonial
          imgSrc="https://img.freepik.com/free-photo/shattered-roof-tiles_1353-251.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
          name="Lead Scrap Buyer"
          title="Business Owner"
          company="The Local Company"
          content="We are dedicated to purchasing lead scrap and offer convenient collection services at your location, whether it’s your home, office, factory, or company. Trust us for dependable and efficient lead scrap buying solutions."
        />
      ),
    },
  ];
  
// ];
