import React from 'react'
import { Helmet } from 'react-helmet';
import Banner from '../../component/BannerImage/Banner'
import Newsletter from '../../component/NewsLetter/Newsletter1'
import Card from '../../component/Card/Card'
import CardStyling from '../../component/CardStyling/CardStyling'
import Testimonals from '../../component/Testimonals/Testimonal'
import Example from '../../component/Example/Example'
import Header from '../../component/Header/Header'
import Footer from '../../component/Footer/Footer'
import Team from '../../component/Team/Team'

const Aboutus = () => {
  return (
<>
<Helmet>
        <title>About Us | My Care Trading</title>
        <meta name="description" content="Learn about My Care Trading, a subsidiary of My Care organization, specializing in the import and export of heavy metal scrap. Discover our mission, values, and commitment to sustainability." />
        <meta name="keywords" content="about us, My Care Trading, scrap metal, mission, values, sustainability" />
        <meta name="author" content="My Care Trading" />
        <meta property="og:title" content="Home - My Care Trading" />
        <meta property="og:description" content="Welcome to My Care Trading. Explore our global import and export services, sustainable recycling solutions, and expertise in heavy metal scrap trading." />
        <meta property="og:image" content="https://mycaretrading.com/wp-content/uploads/2024/04/image-1-5-2048x1866.png" />
        <meta property="og:url" content="https://mycaretrading.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
<Header/>
   <Banner/>
   <Team/>
   <Testimonals/>

   <Card/>
   <Example/>
   <CardStyling/>
   <Footer/>
  
   {/* <Newsletter/> */}
   </>
   
  )
}

export default Aboutus
