import React, { useEffect, useState } from 'react';

const PriceDisplay = ({ value }) => {
  const formatValue = (value) => {
    return value.toFixed(2);
  };

  return (
    <span>
      {formatValue(value)}
      <span style={{ fontSize: '0.5em' }}>USD/Kg</span>
    </span>
  );
};

const UserStatsCard = ({ title, value, percentage }) => {
  return (
    <section
      className="rounded-md p-6 text-center shadow-lg md:p-12 md:text-left"
      style={{
        backgroundImage: 'url("https://tecdn.b-cdn.net/img/Photos/Others/background2.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-2 md:p-6">
        <div className="flex justify-between">
          <div>
            <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">
              {value ? <PriceDisplay value={value} /> : "Loading..."}
            </h5>
            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
              {title} {" "} USD
            </p>
          </div>
          <div className="flex items-center px-2.5 py-0.5 text-base font-semibold text-blue-500 dark:text-blue-500 text-center">
            {percentage}%
            <svg className="w-3 h-3 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13V1m0 0L1 5m4-4 4 4" />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

const UserStatsCardsContainer = () => {
  const [data, setData] = useState({});
  const [usersThisWeek, setUsersThisWeek] = useState(32400); // Example static data

  useEffect(() => {
    fetch("https://api.metals.dev/v1/latest?api_key=UF4OABHL1RMP79TOQXLR512TOQXLR&currency=USD&unit=kg")
      .then(response => response.json())
      .then(data => {
        setData(data);
      });
  }, []);

  const cardData = [
    { title: "Aluminum", value: data?.metals?.aluminum, percentage: 12 },
    { title: "Copper", value: data?.metals?.copper, percentage: 8 },
    { title: "Silver", value: data?.metals?.silver, percentage: 15 },
    { title: "Zinc", value: data?.metals?.lme_zinc, percentage: 10 },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-8">
      {cardData.map((card, index) => (
        <UserStatsCard
          key={index}
          title={card.title}
          value={card.value}
          percentage={card.percentage}
        />
      ))}
    </div>
  );
};

export default UserStatsCardsContainer;
