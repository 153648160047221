import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../component/Header/Header';
import { Footer } from '../../component/Footer/Footer';
import Banner from '../../component/Banner/Banner';
import UserStatsCard from '../../component/Graphs/UserStatsCard';
import { Supports } from '../../component/metals/Supports';
import Hero from '../../component/Herosection/Hero';
import CardCarousel from '../../component/CardCarousel/Caraousel';
import CollapseCardFeatures from '../../component/CollapseCardFeatures/CollapseCardFeatures';
import { VanishText } from '../../component/VanishText/VanishText';
import TabsFeatures from '../../component/Tabs/TabsFeatures';
import ImageGridHero from '../../component/ImageGridHero/ImageGridHero';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>My Care Trading | Sustainable Scrap Metal Solutions</title>
        <meta name="description" content="My Care Trading specializes in the import and export of heavy metal scrap, offering reliable and eco-friendly recycling solutions. Partner with us for sustainable scrap metal management." />
        <meta name="keywords" content="My Care Trading, scrap metal, import, export, recycling, sustainable solutions" />
        <meta name="author" content="My Care Trading" />
        <meta property="og:title" content="Home - My Care Trading" />
        <meta property="og:description" content="Welcome to My Care Trading. Explore our global import and export services, sustainable recycling solutions, and expertise in heavy metal scrap trading." />
        <meta property="og:image" content="https://mycaretrading.com/wp-content/uploads/2024/04/image-1-5-2048x1866.png" />
        <meta property="og:url" content="https://mycaretrading.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <Banner />
      <br />
      <VanishText/>

      <UserStatsCard />
      <br />
      <br />
      <Supports />
      <br></br>
      <br></br>
      <br></br>
      
      <br />
      <br />
      <CardCarousel />
      <br />
      <br></br>
      {/* <TabsFeatures/> */}
      {/* <Hero />
       */}
       <ImageGridHero/>
      <br />
      <CollapseCardFeatures />
      <Footer />
    </>
  );
}

export default Home;
